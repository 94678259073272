import React, { useState} from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { navigate } from "@reach/router"

const NudgeTitle1 = styled.div`
  color: ${props => props.theme.colors.accent_yellow};
  font: 22px/24px Abril Fatface;
  margin-bottom:18px;
  text-align:center;
`
const NudgeTitle2 = styled.div`
  color: ${props => props.theme.colors.accent_yellow};
  font: 30px/36px Abril Fatface;
  margin-bottom:12px;
  text-align:left;
  margin-right: 10px;
  margin-left: 10px;
`

const NudgeSubtitle = styled.div`
  color: ${props => props.theme.colors.white};
  font: 20px/22px Roboto;
  font-weight:450;
  margin-bottom:16px;
  text-align:center;
  margin-right: 10px;
  margin-left: 10px;
`

const ButtonOutline2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} !important;
  background-color: ${props => props.theme.colors.white} !important;
  border-color: ${props => props.theme.buttons.secondary_big_button_violet.normal.border_color} !important;
  font: 18px/20px Roboto;
  transition: 0.25s ease-out;
  width:100%;
  margin-bottom:10px;
  margin-right: 30px;
  margin-left: 30px;
  border-radius:10px;
  &:hover {
    color: ${props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_small_button_yellow_gray.normal.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
`

const FormControlSubmit = styled(Form.Control)`
  margin-top: 10px;
  width: 94%;
  margin-right: 10px;
  margin-left: 10px;
  font: 18px/26px Roboto;
  margin-bottom:12px;
`
const ButtonSubmit = styled(Button)`
  height: 50px;
  width:100px;
  color: ${props =>
    props.theme.buttons.primary_button_yellow_violet.normal.color};
  background-color: ${props =>
    props.theme.buttons.primary_button_yellow_violet.normal.background_color};
  border-color: ${props =>
    props.theme.buttons.primary_button_yellow_violet.normal.border_color};
  transition: 0.25s ease-out;
  &:hover {
    margin-top: 7px;
    color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.hovered
        .color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.hovered
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px
      ${props =>
        props.theme.buttons.primary_button_yellow_violet.hovered
          .shadow} !important;
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.pressed
        .color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_yellow_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
  }
`
const SvgIcon = styled.img`
   margin-bottom: 25px;
   margin-top:25px;
   width: 200px;
`

function NudgeContent() {
  const [buttonValue, setButtonValue] = useState('');
  const firstButton1Clicked = () => {
    document.getElementById("chooseIntention").style.display = "none";
    document.getElementById("inputData").style.display = "block";
    setButtonValue('Look for a mentor');
  }

  const firstButton2Clicked = () => {
    document.getElementById("chooseIntention").style.display = "none";
    document.getElementById("inputData").style.display = "block";
    setButtonValue('Find a speaker for my event');
  }

  const firstButton3Clicked = () => {
    document.getElementById("chooseIntention").style.display = "none";
    document.getElementById("inputData").style.display = "block";
    setButtonValue('Find a candidate for my company');
  }

  const firstButton4Clicked = () => {
    document.getElementById("chooseIntention").style.display = "none";
    document.getElementById("inputData").style.display = "block";
    setButtonValue('Want to know more about Womenworks');
  }

  const { handleSubmit, register} = useForm()
  const [isSubmitting, setSubmitting] = useState(false)

  const onSubmit = values => {
    setSubmitting(true);
    async function submitForm() {
      const response = await fetch(
        "https://asia-southeast2-womenworks-app.cloudfunctions.net/nudgeHttpRequest-submit",
        // "http://localhost:5001/womenworks-app/asia-southeast2/nudgeHttpRequest-submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            personalEmail: values.personalEmail,
            workingEmail: values.workingEmail,
            name: values.name,
            intention : buttonValue,
          }),
        }
      )
      // console.log("hasil: ", values.name, values.email, buttonValue);
      if (response.status && response.status === 200) {
      // if (true) {
        document.getElementById("inputData").style.display = "none";
        document.getElementById("nudgeDone").style.display = "block";

        if (buttonValue === "Find a candidate for my company"){
          setTimeout(() => navigate("/womenbiz-services/"), 1000);
        }else if (buttonValue === "Engage Womenworks mentor for trainings "){
          setTimeout(() => navigate("/womenbiz-services/"), 1000);
        }else if (buttonValue === "Hire a speaker for my company "){
          setTimeout(() => navigate("/book-a-speaker/"), 1000);
        }else {
          setTimeout(() => navigate("/the-ladybosses/"), 1000);
        }
      } else {
        console.log("Error - response.status:", response.status)
        setSubmitting(false);
      }
    }
    submitForm()
  }
  return (
    <div>
      <div id="chooseIntention">
      <NudgeTitle1>Help us understand you better!</NudgeTitle1>
      <NudgeSubtitle>Can we know your intention here?</NudgeSubtitle>
      <Row css={css`margin:0px;`}><ButtonOutline2 onClick={firstButton1Clicked}>Find a candidate for my company</ButtonOutline2></Row>
      <Row css={css`margin:0px;`}><ButtonOutline2 onClick={firstButton2Clicked}>Engage Womenworks mentor for trainings </ButtonOutline2></Row>
      <Row css={css`margin:0px;`}><ButtonOutline2 onClick={firstButton3Clicked}>Hire a speaker for my company </ButtonOutline2></Row>
      <Row css={css`margin:0px;`}><ButtonOutline2 onClick={firstButton4Clicked}>Look for a mentor</ButtonOutline2></Row>
      </div>
      <div id="inputData" css={css`display:none;`}>
      <NudgeTitle2>Submit and get the reward!</NudgeTitle2>
      <NudgeSubtitle css={css`text-align:left;`}>Let us know your working email and get free masterclass content from us!</NudgeSubtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
                <FormControlSubmit
                  name="name"
                  type="text"
                  placeholder="Enter your name *"
                  ref={register({
                    required: "Required",
                  })}
                />
                <FormControlSubmit
                  name="personalEmail"
                  type="email"
                  placeholder="Enter your personal email *"
                  ref={register({
                    required: "Required",
                  })}
                />
                <FormControlSubmit
                  name="workingEmail"
                  type="email"
                  placeholder="Enter your working email"
                  ref={register({})}
                />
                <Row css={css`justify-content:right; margin-right:10px;`}>
                <ButtonSubmit disabled={isSubmitting} variant="warning" type="submit">
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Submit
                </ButtonSubmit>
                </Row>
              </Form>
      </div>
      <div  id="nudgeDone" css={css`display:none;`}>
      <Row css={css`justify-content:center; margin:0px;`}>
        <SvgIcon src="/nudge-thankyou-section.svg"/> 
      </Row>
      <NudgeTitle2 css={css`text-align:center;`}>Thank you for your interest!</NudgeTitle2>
      <NudgeSubtitle>We'll send your reward to your email and let's keep in touch!</NudgeSubtitle>
      </div>
  </div>
  );
}

export default NudgeContent;